import React from "react";
import { Main } from "./components/Main";
import { Footer } from "./components/Footer";

function App() {
  console.log("App ");

  return (
    <div>
      <Main />

      <Footer />
    </div>
  );
} //App

export default App;
